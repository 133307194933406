<template>
  <g>
    <svg:style>
      .cls-2, .cls-3, .cls-4 {
        stroke: #aeaeae;
        stroke-miterlimit: 10;
      }

      .cls-2, .cls-3 {
        stroke-width: 0.25px;
      }

      .cls-2 {
        fill: url(#inox-gradient);
      }

      .cls-4 {
        fill: url(#glass-pattern);
      }
    </svg:style>
    <linearGradient id="inox-gradient"
                    x1="70.81"
                    :y1="doorTopHeight"
                    x2="70.81"
                    :y2="doorTopHeight + doorHeight"
                    gradientUnits="userSpaceOnUse">
      <stop offset="0.01" stop-color="#babbbc"/>
      <stop offset="0.29" stop-color="#989a9e"/>
      <stop offset="0.65" stop-color="#bdbdc1"/>
      <stop offset="0.87" stop-color="#9d9fa2"/>
      <stop offset="1" stop-color="#a6a8aa"/>
      <stop offset="1" stop-color="#c2c5c9"/>
    </linearGradient>
    <linearGradient id="glass-gradient" x1="70.81" y1="240.9" x2="70.81" y2="59.92" gradientUnits="userSpaceOnUse">
      <stop offset="0" stop-color="#f4f4f4"/>
      <stop offset="0.5" stop-color="#dee8e8"/>
      <stop offset="1" stop-color="#d1cece"/>
    </linearGradient>
    <linearGradient id="handle-gradient" x1="11.29" y1="150.41" x2="16.95" y2="150.41" xlink:href="#inox-gradient"/>
    <filter id="inset-shadow">
      <feOffset dx="0" dy="0"/>
      <feGaussianBlur stdDeviation="1"  result="offset-blur"/>
      <feComposite operator="out" in="SourceGraphic" in2="offset-blur" result="inverse"/>
      <feFlood flood-color="black" flood-opacity="1" result="color"/>
      <feComposite operator="in" in="color" in2="inverse" result="shadow"/>
      <feComponentTransfer in="shadow" result="shadow">
        <feFuncA type="linear" slope="1.25"/>
      </feComponentTransfer>
      <feComposite operator="over" in="shadow" in2="SourceGraphic"/>
    </filter>
    <g id="V05">
      <rect id="inox"
            v-if="showInox"
            data-name="inox"
            class="cls-2"
            :x="`${doorLeftWidth1 + 53.14}`"
            :y="inoxY" width="35.35" :height="inoxHeight"/>
      <line id="inox-2" v-if="showInox" data-name="inox" class="cls-3" :x1="`${doorLeftWidth1 + 0.13}`" :y1="`${doorTopHeight + 296.75}`" x2="0.13" y2="292.58"/>
      <polyline filter="url(#inset-shadow)" id="glass"
                class="cls-4"
                :points="`${doorLeftWidth1 + 58.09} ${doorTopHeight + 240.9} ${doorLeftWidth1 + 83.54} ${doorTopHeight + 240.9} ${doorLeftWidth1 + 83.54} ${doorTopHeight + 59.92} ${doorLeftWidth1 + 58.09} ${doorTopHeight + 59.92} ${doorLeftWidth1 + 58.09} ${doorTopHeight + 240.9}`"/>
    </g>
  </g>
</template>

<script>
export default {
  props: [
    'doorWidth',
    'doorHeight',
    'doorLeftWidth',
    'doorTopHeight',
    'doorGlassImage',
    'showInox',
    'showBg',
  ],
  data() {
    return {
      inoxOffset: 22.18,
      leafOffset: 6.4,
    }
  },
  computed: {
    inoxY() {
      return this.showBg ?
        this.doorTopHeight + this.inoxOffset :
        this.doorTopHeight + this.leafOffset
    },
    inoxHeight() {
      return this.showBg ?
        this.doorHeight - 2 * this.inoxOffset :
        this.doorHeight - 2 * this.leafOffset
    },
    doorLeftWidth1() {
      return this.doorLeftWidth + this.doorWidth/2 - 71
    },
  },
}
</script>
